

import React from "react"
import { graphql } from "gatsby"

import Bio from "../../components/bio"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const CalgaryHerald = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      <Bio />
      <h2>Reaching out and bringing kids back from the street</h2>
      <p><em>
By Nicole Dunsdon</em><br/><br/><em>'The seeds of positive growth or benign neglect of teens are nurtured in the family environment.'</em><br/><br/>
    
A teen can be in serious trouble, living on the streets, hooked on drugs, selling sex to survive - and all thats needed to turn things around is one person who cares.<br/><br/>

Rabbi Yehuda Fine spent a decade of his life doing just that. Hanging out in the underbelly of New York, reaching out to kids who lived lives of hell. He sat, he listened and he cared.<br/><br/>

He single-handedly nurtured hundreds of kids back from the edges of society.<br/><br/>

I know what kindness can do for a child whose only memory of their family is not of love but of the cigarette burn on their chest, says Fine. Then someone like me comes along and doesnt abuse them or try to educate them, but sees into their heart and soul.<br/><br/>

This week, during a presentation at the Calgary City Teachers Convention, participants can hear the unorthodox rabbi - clad in a New York Yankees ballcap - tell his remarkable story. From him, they will hear a first-hand account of how to build unique relationships with kids that can change their lives.<br/><br/>


The teachers role is very important because many students dont have any in-depth contact with adults outside of school, says Fine. So [teachers] need to see themselves as educators and mentors in order to help young people mature, develop and think things through regarding the decisions in their lives.<br/><br/>

Fine discusses the problem in his book, Times Square Rabbi: Finding the Hope in Lost Kids Lives. Each chapter introduces one step in Fines eight-step program by describing a young kid who has grown old and hard from abuse, violence and neglect.<br/><br/>

Each step is based on work by the 12th Century Jewish scholar Moses Maimonides, whose Hilchos Teshuvah translates loosely as the Path to Hope and Meaning. The steps range from confession, which he calls hurling out the pain, to redemption, falling in love with life.<br/><br/>

Thats as close as Fine comes to sounding like a rabbi. From there his frank ability to address the real concerns of teens - sex, drugs, suicide - is startling.<br/><br/>

After spending every night on the street from eight to five in the morning for 10 years, Fine says he couldnt be out there any more. But he uses his experiences to continue to help teens and their families.<br/><br/>

Many lessons I learned have relevance to kids and parents today and within 15 minutes of hearing me talk about my life on the streets I get instant credibility, he says.<br/><br/>

I would go visit kids and give them peanut butter sandwiches and hot chocolate. They were just sandwiches, but [the kids] knew they were made with a lot of love, says Fine.<br/><br/>

From there I became part of their world. I'd have hangouts where kids would meet me all over the city to talk.<br/><br/>

Things got rough a number of times for Fine, but he saw how important his help was to the kids he knew when a couple of them threw themselves in front of him as a gunman approached.<br/><br/>

It was intense and I've been in some very dangerous places - your life is never the same after youve fought for your life, he says. If the bravest person is a 10, Im a one - that's the big lesson for kids. They see that were all imperfect but that shouldnt get in the way of our dreams.<br/><br/>

Fine shares his messages of spiritual imperfection, forgiveness and healing with thousands of kids, educators and parents every year. He recounts his experiences on the streets to show students the consequences of certain choices - like involvement with sex, drugs and alcohol.<br/><br/>

I survey them on all the issues of their lives through a show of hands. I ask them if they know somebody who has seriously contemplated suicide, says Fine. The response has never been less than 80 per cent who raise their hand. This brings them all together - the athlete, the stoner, the nerd, the academic - and they realize that they are all in the tough terrain of adolescence together.<br/><br/>

According to Fine its during this rite of passage that kids need the most care.<br/><br/>

Adults tend to be afraid of hulking teenagers S and we need to get rid of the myth that we shouldnt talk to them or interrupt their transit into adult society, he says.<br/><br/>

At that age you need feedback because you are trying to find your place, but that is exactly when we let go of our children.<br/><br/>

The school has an important place during this formative time. Teachers need to know about the kinds of trouble kids are in and how that presents itself, Fine says. They need to know what kinds of questions to ask to really understand what is going on.<br/><br/>

This may sound like a formidable effort in an era of large class sizes and funding cuts, but the payback is worth it.<br/><br/>

Once teachers learn how to engage kids, then (the kids) academic performance goes up because their respect for the teacher goes through the roof, says Fine.<br/><br/>

Copyright © 2005 Calgary Herald New Media
<hr/>
</p>
    </Layout>
  )
}

export default CalgaryHerald

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`